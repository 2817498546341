<template>
    <div class="login-page">
        <div class="login-area" v-if="!loginSuccess">
            <div class="title">主理人及管理员登录</div>
            <div id="login_container" class="qrcode-area">
                <img v-if="this.code" src="/assets/images/lqr.png" alt="">
            </div>
            <div>
                <span v-if="code">已扫码</span>
                <span v-else>微信扫描二维码登录</span>
            </div>
        </div>

        <div v-if="error" class="error">
            <span class="iconfont pc-tishi"></span>
            <span>你的微信暂未绑定任何小程序</span>
        </div>

        <div class="apps-area" v-if="showAppsArea">

            <div class="area-title">请选择主理人小程序</div>
            <div class="app-list">

                <div class="apps-container" v-if="!appGroups.length ">

                    <template v-for="app of apps">

                        <div class="app-item" :key="app.id"
                             :class="{active: app.id === selectedAppId }"
                             @click=" selectedAppId = app.id "
                        >

                            <template v-if="app.avatar">
                                <div class="app-avatar">
                                    <div v-if=" app.master_type === 'trusteeship' " class="trusteeship"></div>
                                    <img :src="app.avatar" alt="">
                                </div>
                            </template>

                            <div class="app-name">{{ app.nickname }}</div>

                        </div>

                    </template>

                </div>
                <div class="apps-container" v-else>

                    <a v-if="appGroupIdx !== 0" @click="appGroupSwitch(-1)" class="app-arrow-left"/>
                    <a v-if="appGroupIdx !== (appGroups.length - 1)" @click="appGroupSwitch(1)"
                       class="app-arrow-right"/>

                    <template v-for="app of appGroups[appGroupIdx]">

                        <div class="app-item" :class="{active: app.id === selectedAppId }" :key="app.id"
                             @click=" selectedAppId = app.id ">

                            <template v-if="app.avatar">
                                <div class="app-avatar">
                                    <div v-if=" app.master_type === 'trusteeship' " class="trusteeship"></div>
                                    <img :src="app.avatar" alt="">
                                </div>
                            </template>

                            <div class="app-name">{{ app.nickname }}</div>

                        </div>

                    </template>

                </div>

            </div>
            <div class="goto-btn" @click="gotoXcx" :class="{active:!!selectedAppId}">进入</div>
        </div>

    </div>
</template>

<script>
import auth from "@/auth";
import api from "../../repo/api";

export default {
    name: "Login",
    data() {
        return {
            state: 'collocation_login',
            code: 0,
            error: false,
            loginSuccess: true,
            showAppsArea: false,
            apps: [],
            appGroups: [],
            appGroupIdx: 0,
            selectedAppId: 0,
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            if (this.queryHasCode()) {
                return this.validateCode()
            }

            auth.check((success) => {

                if (success) return this.loginFinished()

                return this.createQRCode();
            })

        },
        queryHasCode() {
            return this.$route.query.code && (this.$route.query.state === this.state);
        },
        validateCode() {
            let code = this.$route.query.code;

            this.code = code;

            auth.appId(0)

            auth.login(code, (success, errorCode) => {

                if (success) {
                    return this.loginFinished()
                }

                if (errorCode === -100) {
                    this.error = true
                    return;
                }

                return this.createQRCode();
            })
        },
        loginFinished() {

            this.loginSuccess = true

            this.checkPt().catch(() => {

                if (auth.appId()) {
                    return this.getAppStatus()
                }

                this.getAppList()
            })
        },
        getAppStatus() {

            api.get('/ast-app/status', (data) => {

                if ((!data.data) || (!data.data.status)) {

                    return this.getAppList()
                }
                this.selectedAppId = auth.appId()

                return this.gotoXcx();
            });
        },
        gotoReg() {
            return this.$router.push('/ast/reg');
        },
        checkPt() {
            return new Promise((resolve, reject) => {
                api.get('/ast-reg/waite-reg', (data) => {

                    if (!data.data || !data.data.has) {

                        reject()

                        return
                    }

                    resolve()

                    auth.appId(0);

                    this.gotoReg();
                })
            })
        },
        getAppList() {

            this.$loading.show()

            api.get('/ast-app/apps', (data) => {

                this.$loading.hide();

                let list = data.data

                console.log('获取小程序列表成功');

                if (list.length === 1) {

                    auth.appId(list[0].id)

                    return this.$router.push('/ast/xcx/base');

                } else if (list.length === 0) {

                    auth.loginOut()

                    this.error = true

                    console.log('app not found.');
                    return
                }


                this.apps = list
                this.appGroups = [];
                this.appGroupIdx = 0;

                auth.appLength(list.length)

                if (list.length > 3) {

                    while (list.length > 3) {
                        this.appGroups.push(list.splice(0, 3))
                    }

                    if (list.length) this.appGroups.push(list)
                }

                this.showAppsArea = true
            })

        },
        gotoXcx() {

            if (!this.selectedAppId) return;

            auth.appId(this.selectedAppId)

            return this.$router.push('/ast/xcx/base');
        },
        appGroupSwitch(number) {
            this.appGroupIdx += number
        },
        createQRCode() {

            this.loginSuccess = false

            this.code = 0;

            setTimeout(() => {
                /* eslint-disable */
                new WxLogin({
                    self_redirect: false,
                    id: "login_container",
                    appid: "wx7178015fd2dd487c",
                    scope: "snsapi_login",
                    redirect_uri: encodeURI(process.env.VUE_APP_URL + '/login'),
                    state: this.state,
                    href: "https://col.ycsd.work/assets/css/wx.css",
                });
            }, 200)

            console.clear()
        }
    }
}
</script>

<style lang="less" scoped>

.login-page, .login-area {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login-page {
    height: 100vh;
    background-image: url("/assets/images/bg.png");
    background-size: cover;
    background-position: center;

}

.login-area {
    width: 400px;
    height: 400px;
    background-color: rgba(255, 255, 255, 0.9);
    font-size: 16px;
    line-height: 22px;
    position: relative;
}

.title {
    margin-bottom: 24px;
}

.qrcode-area {
    width: 240px;
    height: 240px;
    border: 1px solid #333333;
    margin-bottom: 32px;
}

#login_container /deep/ iframe {
    width: 100%;
    height: 100%;
}

#login_container img {
    width: 100%;
}

.error {
    position: absolute;
    top: 120px;

    padding: 9px 20px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 21px;
    font-size: 14px;
    font-weight: 400;

    display: flex;
    align-items: center;

    line-height: 20px;

    span {
        color: #FFFFFF;
    }

    .iconfont {
        margin-right: 5px;
    }
}

.apps-area {
    width: 400px;
    height: 400px;
    background: rgba(255, 255, 255, 0.9);
    padding: 34px 16px 32px 16px;

    .area-title {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        text-align: center;
    }

    .app-list {
        height: 176px;
        margin-top: 48px;
        padding-left: 22px;
        padding-right: 22px;

    }

    .goto-btn {
        cursor: pointer;
        margin: 48px auto 0;
        width: 240px;
        height: 40px;
        background: rgba(51, 51, 51, 0.51);
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 40px;
        text-align: center;
        transition: all .3s;

        &.active {
            background-color: #333333;
        }
    }

    .app-item {
        width: 100px;
        height: 176px;
        margin-right: 12px;
        cursor: pointer;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &.active, &:active, &:hover {
            .app-avatar {
                border-color: #333333;
            }

            .app-name {
                color: #333333;
            }
        }
    }

    .app-avatar {
        width: 96px;
        height: 96px;
        border: 1px solid rgba(51, 51, 51, 0.06);
        padding: 8px;
        border-radius: 8px;
        margin-bottom: 8px;
        transition: all .3s;

        img {
            width: 80px;
            height: 80px;
            border-radius: 8px;
        }
    }

    .app-name {
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(51, 51, 51, 0.4);
        line-height: 20px;
        text-align: center;
        transition: all .3s;
    }

    .apps-container {
        display: flex;
        width: 324px;
        position: relative;
    }

    .app-arrow-left, .app-arrow-right {
        width: 14px;
        height: 14px;

        position: absolute;
        top: 64px;
        background-size: cover;
    }

    .app-arrow-left {
        left: -22px;
        background-image: url("/assets/icons/arrow-left.png");
    }

    .app-arrow-right {
        right: -22px;
        background-image: url("/assets/icons/arrow-right.png");
    }
}

.trusteeship {
    width: 32px;
    height: 42px;
    background-image: url("/assets/icons/tg-icon.png");
    background-size: 100%;
    position: absolute;
    left: 0;
    top: 4px;
}
</style>